<template>
  <div class="news-container">
    <div class="table-content">
      <div class="data-table flex">
        <div class="news-item flex" v-for="(item, index) in dataList" :key="index">
          <img :src="item.cover" class="news-img" />
          <div class="info">
            <div class="title fs-18 fc-3 lm-1" :title="item.title">{{item.title}}</div>
            <div class="des fs-12 fc-6 lm-2" :title="item.description">{{item.description}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination flex" v-show="total > 0">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :total="total"
        :page-size="pageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API } from "@/const/const";

export default {
  name: "newsIndex",
  data() {
    return {
      pageSize: 6,
      page: 1,
      total: 0,
      dataList: sessionStorage.getItem("coursesList")
        ? JSON.parse(sessionStorage.getItem("coursesList"))
        : []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      request
        .get(API.GET_COUSRSES_LIST, {
          page_size: this.pageSize,
          page: this.page
        })
        .then(res => {
          sessionStorage.setItem("coursesList", JSON.stringify(res.data));
          this.dataList = res.data;
          this.page = res.page;
          this.total = res.total;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.news-container {
  padding: 0.56rem 2.8rem 0;
  .table-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .data-table {
    padding-bottom: 0.4rem;
    flex-wrap: wrap;
    justify-content: space-between;
    .news-item {
      flex-direction: column;
      width: 30%;
      margin-bottom: 0.36rem;
      box-shadow: 0 0.06rem 0.42rem 0 rgba(0, 0, 0, 0.1);
      .news-img {
        width: 100%;
      }
      .info {
        .title {
          font-weight: 500;
          margin: 0.16rem 0.2rem;
        }
        .des {
          margin: 0 0.2rem 0.2rem;
        }
      }
    }
  }
  .pagination {
    justify-content: center;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: var(--common-red);
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: var(--common-red);
    }
    /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active:hover {
      color: #fff;
    }
  }
}
</style>